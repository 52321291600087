<template>
    <div>
        <div style="display: flex">


            <el-container>

                <CommonTree :defaultProps="defaultProps" :isShowdig="false" :showCheckbox="true" :searchTitle="searchTitle"
                    :treeData="treedata" ref="tree" :node-key="treekey" :treeExpand="false" :checkStrictly="true"
                    style="font-size: 13px;" @getHandleCheckChange="getHandleCheckChange" />


                <el-main>
                    <head-layout :head-btn-options="headBtnOptions" :head-title="$t('cip.dc.TeamIndicators.title')">
                    </head-layout>
                    <div style="margin: 20px;">
                        <el-date-picker v-model="timeData" :picker-options="setDateRange" value-format="yyyy-MM-dd"
                            type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                        </el-date-picker>
                        <el-button type="primary" @click="timeSearch" style="margin-left: 10px;">查询</el-button>
                    </div>

                    <div v-for="(item, index) in chartList" :ref="item.id" :key="item.id" class="echart" :id=item.id
                        :style="myChartStyle">
                    </div>





                </el-main>







            </el-container>

            <el-drawer :title="tableTitle" :append-to-body="true" :visible.sync="dialog">
                <el-table :data="tableData">

                    <!-- <el-table-column property="bh" label="编号"></el-table-column>
                    <el-table-column property="mc" label="原料产量"></el-table-column> -->
                    <el-table-column property="sj" label="时间"></el-table-column>
                    <el-table-column property="ds" label="值"></el-table-column>


                </el-table>
            </el-drawer>
        </div>

    </div>
</template>

<script>
import HeadLayout from "@/views/components/layout/head-layout"
import GridLayout from "@/views/components/layout/grid-layout";
import CommonTree from "@/views/components/com_tree/index"
import { dataApisTree, apiTreeData, xmsjDay, xmsjHour,starList } from "@/api/dataAcquisition/index";


export default {
    components: {
        CommonTree,
        HeadLayout,
        GridLayout,

    },
    watch: {

    },
    data() {

        return {
            treekey: 'xmmc',
            tableTitle: '',
            searchTitle: 'xmmc',
            setDateRange: {
                onPick: ({ maxDate, minDate }) => {
                    this.choiceDate = minDate.getTime()
                    if (maxDate) {
                        this.choiceDate = ''
                    }
                },
                disabledDate: (time) => {
                    const choiceDateTime = new Date(this.choiceDate).getTime()
                    const minTime = new Date(choiceDateTime).setDate(new Date(choiceDateTime).getDate() - 6)
                    const maxTime = new Date(choiceDateTime).setDate(new Date(choiceDateTime).getDate() + 6)
                    const min = minTime
                    const newDate = new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1
                    const max = newDate < maxTime ? newDate : maxTime
                    const now = new Date()
                    // now.setHours(23, 59, 59, 999)
                    if (this.choiceDate) {
                        return time.getTime() < min || time.getTime() > max || time.getTime() > now
                    }
                    return time.getTime() > now
                }



            },
            tableData: [],
            dialog: false,
            chartList: [],
            // searchTitle: 'name',
            tableLoading: false,
            filterText: '',
            activeName1: '1',
            activeName: 'list',
            defaultProps: {
                children: 'childrenList',
                label: 'xmmc'
            },

            treedata: [],
            barChart: [],
            xData: [], // 横坐标
            yData: [], // 数据
            yData2: [], // 数据2
            yData3: [], // 数据2
            yData4: [], // 数据2
            myChartStyle: { float: "left", width: "100%", height: "400px", margin: "10px" }, // 图表样式
            timeData: [],
            treeParms: {
                xmbh: "c02001",//项目编号
                beginTime: "",//开始时间
                endTime: ""
            },
            startParms: {
                appId: "",
                appNo: "",
                endTime: "2023-05-07",
            },



        };

    },

    methods: {

        //设置默认日期
        defaultDate() {
            //获取新的时间
            let date = new Date()
            //获取当前时间的年份转为字符串
            let year = date.getFullYear().toString()   //
            //获取月份，由于月份从0开始，此处要加1，判断是否小于10，如果是在字符串前面拼接'0'
            let month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1).toString() : (date.getMonth() + 1).toString()  //'04'
            //获取天，判断是否小于10，如果是在字符串前面拼接'0'
            let da = date.getDate() < 10 ? '0' + date.getDate().toString() : date.getDate().toString()  //'12'
            // let aa = (date.getDate()-7) < 10 ? '0' + date.getDate().toString() : (date.getDate()-7)
            //字符串拼接，开始时间，结束时间
            let end = year + '-' + month + '-' + da  //当天




            var currentDate = new Date();
            var date2 = new Date(currentDate.setDate(currentDate.getDate() - 6));
            let year2 = date2.getFullYear().toString()
            let month2 = date2.getMonth() + 1 < 10 ? '0' + (date2.getMonth() + 1).toString() : (date2.getMonth() + 1).toString()
            let da2 = date2.getDate() < 10 ? '0' + date2.getDate().toString() : date2.getDate().toString()
            let beg = year2 + '-' + month2 + '-' + da2    //一周


            this.timeData = [beg, end] //将值设置给插件绑定的数据
            console.log(this.timeData, "ssss", beg);
        },



        //班组tree
        MdataApisTree() {
            dataApisTree().then(res => {
                console.log(res.data.data);
                this.treedata = res.data.data
                this.treedata.forEach(element => {
                    element.disabled = true
                });

            })
        },
        getHandleCheckChange(data, choosed) {
            let all = this.$refs.tree.$refs.commonTree.getCheckedNodes()
            console.log(this.$refs.tree.$refs.commonTree,"aaaaaaaaaaaaaaaaaa");
            let canAdd = true
            if (all.length > 6) {
                let index = all.findIndex(item => item.xmmc === data.xmmc)
                all.splice(index, 1)
                let all2 = []
                all.forEach(element => {
                    all2.push(element.xmmc)
                });

                this.$refs.tree.$refs.commonTree.setCheckedKeys(all2)
                canAdd = false
                this.$message({
                    message: '限制最多勾选6条！',
                    type: 'warning'
                });
            } else {
                canAdd = true
            }
            if (choosed && canAdd) {


                this.treeParms.xmbh = data.xmbh
                this.treeParms.beginTime = this.timeData[0]
                this.treeParms.endTime = this.timeData[1]
                apiTreeData(this.treeParms).then(res => {


                    xmsjDay(this.treeParms).then(res2 => {
                        if(res.data.data.length<1){
                            let all = this.$refs.tree.$refs.commonTree.getCheckedNodes()
                                let index = all.findIndex(item => item.xmmc === data.xmmc)
                                all.splice(index, 1)
                                let all2 = []
                                all.forEach(element => {
                                    all2.push(element.xmmc)
                                });

                                this.$refs.tree.$refs.commonTree.setCheckedKeys(all2)
                            this.$message({
                                    message: '班组此时间段内无数据',
                                    type: 'warning'
                                });
                        }else{
                            let Lsit2 = res2.data.data
                        this.barChart = res.data.data
                        let xAxis = {
                            axisLabel: {
                                interval: 0, rotate: 30
                            },
                            data: [],

                        }
                        let yData = []
                        let yData2 = []
                        let yData3 = []
                        let yData4 = []
                        this.barChart.forEach(element => {
                            xAxis.data.push(element.sj.slice(0, element.sj.length - 8))
                            yData.push(element.earlyDs)
                            yData2.push(element.middleDs)
                            yData3.push(element.nightDs)
                        });

                        this.barChart.forEach((element, index) => {
                            Lsit2.forEach((element2, index2) => {
                                if (index == index2) {
                                    yData4.push(element2.ds)
                                }
                            });

                        });




                        let option = {
                            title: {
                                show: true,//显示策略，默认值true,可选为：true（显示） | false（隐藏）
                                text: this.barChart[0].xmmc,//主标题文本，'\n'指定换行
                            },
                            xAxis: xAxis,
                            yAxis: {},
                            legend: {
                                data: ["早", "白", "晚", "天"],
                                top: "0%", // 设置图例位置在顶部
                            },
                            series: [
                                {
                                    type: "bar", // 形状为柱状图
                                    data: yData,
                                    name: "早", // legend属性
                                    label: {
                                        // 柱状图上方文本标签，默认展示数值信息
                                        show: true,
                                        position: "top",
                                    },
                                },
                                {
                                    type: "bar", // 形状为柱状图
                                    data: yData2,
                                    name: "白", // legend属性
                                    label: {
                                        // 柱状图上方文本标签，默认展示数值信息
                                        show: true,
                                        position: "top",
                                    },
                                },
                                {
                                    type: "bar", // 形状为柱状图
                                    data: yData3,
                                    name: "晚", // legend属性
                                    label: {
                                        // 柱状图上方文本标签，默认展示数值信息
                                        show: true,
                                        position: "top",
                                    },
                                },
                                {
                                    type: "line", // 形状为柱状图
                                    data: yData4,
                                    name: "天", // legend属性
                                    symbolSize: 10,
                                    label: {
                                        // 柱状图上方文本标签，默认展示数值信息
                                        show: true,
                                        position: "top",
                                    },
                                },
                            ]
                        }



                        let id = this.barChart[0].xmbh
                        this.chartList.push({ id: id, name: this.barChart[0].xmmc })
                        let that = this
                        this.$nextTick(() => {
                            this.initEcharts(id, option);
                            let myChart = this.$echarts.init(document.getElementById(id));
                            myChart.on('click', function (params) {
                                if (params.componentSubType == 'line') {
                                    that.dialog = true
                                    let parms = {
                                        xmbh: id,
                                        beginTime: params.name
                                    }
                                    xmsjHour(parms).then(res => {
                                        that.tableData = res.data.data
                                        that.tableTitle = res.data.data[0].mc
                                    })

                                }


                            });
                        });






                        }











                    })


                })

            }


            if (!choosed) {
                this.chartList.forEach((element, index) => {
                    if (element.id == data.xmbh) {
                        let myChart = this.$echarts.init(document.getElementById(data.xmbh));
                        myChart.dispose();
                        this.chartList.splice(index, 1);
                    }
                });

            }





        },


        filterNode(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },

        //查询时间更新echarts
        timeSearch() {
            this.chartList.forEach(element => {
                let Tparms = {
                    xmbh:element.id,
                    beginTime : this.timeData[0],
                    endTime : this.timeData[1]
                }
                console.log(element);

                apiTreeData(Tparms).then(res=>{
                    xmsjDay(Tparms).then(res2 => {
                        let Lsit2 = res2.data.data
                        this.barChart = res.data.data
                        let xAxis = {
                            axisLabel: {
                                interval: 0, rotate: 30
                            },
                            data: [],
                        }
                        let yData = []
                        let yData2 = []
                        let yData3 = []
                        let yData4 = []
                        this.barChart.forEach(element => {
                            xAxis.data.push(element.sj.slice(0, element.sj.length - 8))
                            yData.push(element.earlyDs)
                            yData2.push(element.middleDs)
                            yData3.push(element.nightDs)
                        });

                        this.barChart.forEach((element, index) => {
                            Lsit2.forEach((element2, index2) => {
                                if (index == index2) {
                                    yData4.push(element2.ds)
                                }
                            });

                        });

                        let option = {
                            title: {
                                show: true,//显示策略，默认值true,可选为：true（显示） | false（隐藏）
                                text: this.barChart[0].xmmc,//主标题文本，'\n'指定换行
                            },
                            xAxis: xAxis,
                            yAxis: {},
                            legend: {
                                data: ["早", "白", "晚", "天"],
                                top: "0%", // 设置图例位置在顶部
                            },
                            series: [
                                {
                                    type: "bar", // 形状为柱状图
                                    data: yData,
                                    name: "早", // legend属性
                                    label: {
                                        // 柱状图上方文本标签，默认展示数值信息
                                        show: true,
                                        position: "top",
                                    },
                                },
                                {
                                    type: "bar", // 形状为柱状图
                                    data: yData2,
                                    name: "白", // legend属性
                                    label: {
                                        // 柱状图上方文本标签，默认展示数值信息
                                        show: true,
                                        position: "top",
                                    },
                                },
                                {
                                    type: "bar", // 形状为柱状图
                                    data: yData3,
                                    name: "晚", // legend属性
                                    label: {
                                        // 柱状图上方文本标签，默认展示数值信息
                                        show: true,
                                        position: "top",
                                    },
                                },
                                {
                                    type: "line", // 形状为柱状图
                                    data: yData4,
                                    name: "天", // legend属性
                                    symbolSize: 10,
                                    label: {
                                        // 柱状图上方文本标签，默认展示数值信息
                                        show: true,
                                        position: "top",
                                    },
                                },
                            ]
                        }

                        let id = this.barChart[0].xmbh
                        let that = this
                        let myChart = this.$echarts.init(document.getElementById(element.id));
                myChart.dispose();
                        this.$nextTick(() => {
                            this.initEcharts(id, option);
                            let myChart = this.$echarts.init(document.getElementById(id));
                            myChart.on('click', function (params) {
                                if (params.componentSubType == 'line') {
                                    that.dialog = true
                                    let parms = {
                                        xmbh: id,
                                        beginTime: params.name
                                    }
                                    xmsjHour(parms).then(res => {
                                        that.tableData = res.data.data
                                        that.tableTitle = res.data.data[0].mc
                                    })

                                }


                            });
                        });


                    })
                })

            });


        },


        initEcharts(id, option) {

            const myChart = this.$echarts.init(document.getElementById(id));
            myChart.setOption(option);
            // 随着屏幕大小调节图表
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },


        delChart() {
            this.chartList.forEach((element, index) => {
                console.log(element.id, document.getElementById(element.id));
                let myChart = this.$echarts.init(document.getElementById(element.id));
                myChart.dispose();
                this.chartList.splice(index, 1);
            });
        },

        MstarList(e) {
            starList(e).then(res => {
                this.treedata = res.data.data.treeList.xmmcList
                this.treedata.forEach(element => {
                    element.disabled = true
                });








            })

        },





    },

    mounted() {
        // this.MdataApisTree()
        // this.startParms.endTime = this.timeDefault()
        this.defaultDate()

        this.startParms.appNo = "BZZB"
        this.MstarList(this.startParms)

    },
    beforeDestroy() {
        // this.delChart()

    }
};
</script>

<style scoped lang="scss">
::v-deep .el-button+.el-button {
  margin-left: 0px;
}

::v-deep .avue-crud .el-table {
    height: calc(100vh - 218px) !important;
    max-height: calc(100vh - 218px) !important;
}
</style>
